import { SolutionsAndServicesProps } from '../components/SolutionsAndServices/type'

let localprops: SolutionsAndServicesProps = {
  language: 'en',
  country: 'US',
  properties: { useOrgedAuthProvider: null },
  criterion: null
}

export const setProps = (props: SolutionsAndServicesProps) => {
  localprops = props
}

export const getProps = (): SolutionsAndServicesProps => {
  return localprops
}
