import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Card, ProgressIndicator } from '@veneer/core'

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 56px;
`
export const CardHeaderLabel = styled.span`
  margin: 14px ${primitives.layout.size5}px;
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
`
export const CardFooter = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${primitives.layout.size4}px;
  border-top: 1px solid rgba(33, 33, 33, 0.05);
`
export const Divider = styled.hr`
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  border-bottom: 0px;
  margin: 0;
`
export const ListContainer = styled.div`
  height: 229px;
  padding: 3px ${primitives.layout.size6}px;
`
export const TrailingAreaCount = styled.span`
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight3};
`
export const DevicesText = styled.span`
  margin-left: ${primitives.layout.size1}px;
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight3};
`
export const LoaderCard = styled(Card)`
  width: 100%;
  height: 360px;
  vertical-align: middle;
`
export const StyledLoader = styled(ProgressIndicator)`
  margin-top: 165px;
  div {
    margin: auto;
  }
`
