import { getProps } from './commonMethods'
import { JarvisWebHttpClient } from '@jarvis/web-http'
import { Stack } from '@jarvis/web-stratus-client'

export const getDeviceCount = async (authStack, authProvider) => {
  const props = getProps()

  const urlEnv = (() => {
    switch (authStack) {
      case Stack.prod:
        return ''
      case Stack.stage:
        return 'stage-'
      default:
        return 'pie-'
    }
  })()

  const baseAuthProvider = props?.properties?.useOrgedAuthProvider
    ? authProvider.createOrgedAuthProvider()
    : authProvider

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/devicecache/v1/devices`

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: baseAuthProvider,
    defaultBaseURL: baseUrl
  })

  const responseTotal = await httpClient.head({
    params: {
      serviceId: 'ws-hp.com/smcloud'
    }
  })

  return responseTotal
}

export const isServiceCustomer = async () => {
  const props = getProps()
  const criterion = await props.criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  })
  return criterion
}
