/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Button, Card, IconChevronRight, List, ListItem } from '@veneer/core'
import * as S from './styles'
import { getDeviceCount, isServiceCustomer } from '../../../src/utils/api'
import { setProps, getProps } from '../../../src/utils/commonMethods'
import { useShellRootContext } from '../../contexts/ShellRoot'
import * as T from './type'

const SolutionsAndServices = ({ ...props }: T.SolutionsAndServicesProps) => {
  setProps(props)
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const [devicesCount, setDevicesCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(
    props.hideLoader ? false : true
  )
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const useOrgedAuthProvider = getProps().properties?.useOrgedAuthProvider

  const getDeviceCountFromAPI = async () => {
    const response = await getDeviceCount(authStack, authProvider)
    const xTotalCount = 'x-total-count'
    setDevicesCount(Number(response?.headers?.[xTotalCount]) || 0)
    setIsLoading(false)
  }

  const getServiceCustomer = async () => {
    const response = await isServiceCustomer()
    setIsDisabled(useOrgedAuthProvider && !response)
    return response
  }

  useEffect(() => {
    getServiceCustomer()
    getDeviceCountFromAPI()
  }, [])

  const trailingArea = (count: number | string, index: number) => {
    return (
      <div>
        <S.TrailingAreaCount
          data-testid={`solutions-and-services-widget-listitem-count-${index}`}
        >
          {count}
        </S.TrailingAreaCount>
        <S.DevicesText
          data-testid={`solutions-and-services-widget-devices-span-${index}`}
        >
          {t('ecp-solutions-and-services-widget.devices', 'devices')}
        </S.DevicesText>
      </div>
    )
  }

  const onClick = () => {
    return navigation.push('/solutions')
  }

  const getSolutionsWidget = () => {
    return (
      <>
        {isLoading ? (
          <S.LoaderCard content={<S.StyledLoader />} />
        ) : (
          <>
            <S.CardHeader data-testid="solutions-and-services-widget-card-header">
              <S.CardHeaderLabel data-testid="solutions-and-services-widget-card-header-label">
                {t(
                  'ecp-solutions-and-services-widget.solutionsEntiled',
                  'Solutions Entitled'
                )}
              </S.CardHeaderLabel>
            </S.CardHeader>
            <S.Divider data-testid="solutions-and-services-widget-title-divider" />
            <S.ListContainer>
              <List data-testid="solutions-and-services-widget-list">
                <ListItem
                  className="label"
                  data-testid="solutions-and-services-widget-listitem-1"
                  leadingArea={<>{`HP Secure Fleet Manager`}</>}
                  trailingArea={trailingArea(devicesCount, 1)}
                />
              </List>
            </S.ListContainer>
            <S.CardFooter data-testid="solutions-and-services-widget-card-footer">
              <Button
                data-testid="solutions-and-services-widget-card-footer-button"
                appearance="ghost"
                trailingIcon={<IconChevronRight />}
                onClick={onClick}
                disabled={isDisabled}
              >
                {t(
                  'ecp-solutions-and-services-widget.viewDetails',
                  'View Details'
                )}
              </Button>
            </S.CardFooter>
          </>
        )}
      </>
    )
  }

  return (
    <Card
      data-testid="solutions-and-services-widget-card"
      content={getSolutionsWidget()}
    />
  )
}

export default SolutionsAndServices
